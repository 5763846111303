.loading-placeholder-wrapper-home {
    margin: 20px;
    border-radius: 10px;
    overflow: hidden;
}

.placeholder-shine {
    color: grey;
    -webkit-mask:linear-gradient(-60deg,#000 30%,#0005,#000 70%) right/300% 100%;
    animation: shimmer 2.5s infinite;
    font-size: 50px;
}

@keyframes shimmer {
    100% {-webkit-mask-position:left}
}