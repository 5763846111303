@import url('https://fonts.googleapis.com/css2?family=Readex+Pro:wght@200;400&display=swap');

:root {
    --primary-color: #14dca0;
    --text-color: #072241;
    --swiper-navigation-size: 20px !important;
    --swiper-theme-color: #ffffff !important;
    --balloon-font-size: 14px !important
}

* {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.selectable {
  -webkit-touch-callout: text !important;
  -webkit-user-select: text !important;
  -khtml-user-select: text !important;
  -moz-user-select: text !important;
  -ms-user-select: text !important;
  user-select: text !important;
}

.home-alert {
  background-color: var(--text);
  border: none;
}

.logo-start-text{
  fill: var(--logoStart);
}

.logo-end-text {
  fill: var(--logoEnd);
}

.logo-face {
  fill: var(--logoFace);
}

.logo-goggle {
  fill: var(--logoGoggle);
  filter: var(--logoShadow);
}

.logo-mouth {
  fill: var(--logoMouth);
}

.logo-shine {
  fill: var(--logoShine);
}

* {
  transition: var(--transistion);
}

html, body {
  overflow-x: hidden;
}

body {
    background: var(--bgPrimary);
    margin: 0;
    font-family: 'Readex Pro', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-bottom: 70px;
}

.tooltip {
    font-family: 'Readex Pro', sans-serif !important;
}

#nprogress .bar {
  background: var(--accent) !important;
  height: 10px;
}

#nprogress .peg {
  box-shadow: 0 0 10px var(--accent), 0 0 5px var(--accent) !important;
}

#nprogress .spinner-icon {
  border-top-color: var(--accent) !important;
  border-left-color: var(--accent) !important;
}

a {
  text-decoration: none !important;
}

.non-anchor {
    text-decoration: none;
}

.non-anchor:hover {
    text-decoration: none;
    color: var(--accent);
}

.page-bottom-margin {
  margin-bottom: 20px;
}

@media screen and (min-width: 992px) {
  body {
    margin-bottom: none;
  }
  .mobile-display {
      display: none !important;
  }
}

@media screen and (max-width: 991px) {
  .desktop-display {
      display: none !important;
  }
  .page-bottom-margin {
    margin-bottom: 90px;
  }
  .tooltip {
    display: none !important;
}
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
  background: var(--bgPrimary); 
}
  
::-webkit-scrollbar-thumb {
  background: var(--accent); 
}

::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.primary-button {
  background-color: var(--accent) !important;
  border-color: var(--accent) !important;
}

.primary-button:hover {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px var(--accent);
}

.primary-button:focus {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px var(--accent);
  outline: none !important;
}

.secondary-button:hover {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px var(--bgPrimary);
}

.secondary-button:focus {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px var(--bgPrimary);
  outline: none !important;
}

.btn-primary:hover {
  background-color: var(--accent);
}