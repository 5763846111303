body {
  margin-bottom: 0px !important;
}

.art-video-player.art-control-show .video-player-header, .art-video-player.art-hover .video-player-header {
  opacity: 1 !important;
  visibility: visible !important;
}

.video-player-header {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 0;
  z-index: 21;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 22px;
  color: #ffffff;
  text-shadow: 0px 0px 9px rgba(0, 0, 0, 1);
  background: rgb(0,0,0);
  background: linear-gradient(180deg, rgba(0,0,0,0.8981967787114846) 0%, rgba(0,0,0,0.500437675070028) 40%, rgba(255,255,255,0) 100%);
}

.video-player-episode-controls {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  margin-bottom: 80px;
}

.video-player-episode-controls button {
  padding: 10px 15px;
  background: #000 !important;
}

.video-player-header button {
  background-color: var(--glass);
  border: none;
  margin: 20px;
  font-size: 40px;
  height: 60px;
  width: 60px;
  border-radius: 50% !important;
  padding: 0;
}

.art-progress {
  height: 20px !important;
}

.art-controls-right {
  display:flex !important;
  flex-direction: row-reverse !important;
}

.art-controls-left {
  display: flex !important;
  flex-direction: row !important;
}

.art-controls-left .art-control:nth-child(1) {
  order: 1 !important;
}

.art-controls-left .art-control:nth-child(2) {
  order: 3 !important;
}

.art-controls-left .art-control:nth-child(3) {
  order: 2 !important;
}

.art-controls-left .art-control:nth-child(4) {
  order: 4 !important;
}

.art-controls-left .art-control:nth-child(5) {
  order: 5 !important;
}

.art-video-player .art-bottom .art-progress {
  bottom: 20px !important;
}

.art-video-player .art-bottom .art-progress .art-control-progress {
  height: 20px !important;
  padding-top: 7px !important;
  padding-bottom: 8px !important;
}

.video-player-state-icon {
  background: var(--glass) !important;
  height: 60px !important;
  width: 60px !important;
  font-size: 30px !important;
  border-radius: 50% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.art-video-player:hover .art-mask .art-state {
  transform: scale(1.1) !important;
  color: white !important;
}

.video-player-icon {
  color: white !important;
  transform: scale(1.5) !important;
  height: 30px !important;
  width: 30px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.video-player-icon:hover {
  transform: scale(1.6) !important;
}

.bi-arrows-fullscreen, .bi-fullscreen-exit {
  transform: scale(1) !important;
}

.bi-arrows-fullscreen:hover, .bi-fullscreen-exit:hover {
  transform: scale(1.1) !important;
}

@-webkit-keyframes pulse {
  0% {opacity: 0.5}
  50% {opacity: 1}
  100% {opacity: 0.5}
}

@keyframes pulse {
  0% {opacity: 0.5}
  50% {opacity: 1}
  100% {opacity: 0.5}
}

.art-loading .art-icon-loading {
  width: 50% !important;
  -webkit-animation: pulse 3s infinite ease-in-out;
  -o-animation: pulse 3s infinite ease-in-out;
  -ms-animation: pulse 3s infinite ease-in-out; 
  -moz-animation: pulse 3s infinite ease-in-out; 
  animation: pulse 3s infinite ease-in-out;
}

.art-loading .art-icon-loading img {
  max-width: 250px !important;
  max-height: 150px !important;
}

.video-player-icon {
  font-size: 22px !important;
}

.current-video-player {
  height: 100%;
  padding: 0;
  overflow: hidden !important
}

.modal-fullscreen .modal-body {
  padding: 0 !important;
}

.video-player-style {
  height: 100% !important;
  width: 100% !important;
}

.art-icon {
  margin: 10px !important;
}
.art-bottom {
  height: 120px !important;
  bottom: 0 !important;
}
.art-controls {
  height: auto !important;
  bottom: 15px !important;
}
.art-video-player .art-bottom {
  padding: 50px 30px 0px !important;
}
.art-video-player .art-bottom .art-controls .art-control {
  font-size: 14px !important;
  font-family: 'Readex Pro', sans-serif !important;
}

.art-video-player.art-info-show .art-info {
  display: none !important;
}

.art-video-player .art-mask .art-state {
  opacity: 1 !important;
  bottom: auto !important;
  position: relative !important;
  right: auto !important;
}

.art-video-player .art-control-selector:hover .art-selector-list {
  border-radius: 10px !important;
  background-color: var(--bgPrimary) !important;
  color: var(--text) !important;
  padding: 0 !important;
}

.art-video-player .art-control-selector .art-selector-list .art-selector-item.art-current, .art-video-player .art-control-selector .art-selector-list .art-selector-item  {
  color: var(--text) !important;
}

.art-video-player .art-control-selector .art-selector-list .art-selector-item.art-current, .art-video-player .art-control-selector .art-selector-list .art-selector-item:hover {
  background-color: var(--text) !important;
  color: var(--contrast) !important;
}

.art-video-player .art-bottom .art-controls .art-control-quality {
  margin-right: 10px !important;
}

.art-video-player .art-settings .art-setting-inner {
  font-size: 16px !important;
  font-family: 'Readex Pro', sans-serif !important;
}

.art-subtitle-range {
  -webkit-appearance: none !important;
  width: calc(100% - (70px));
  height: 2px !important;
  border-radius: 5px !important;
  background: rgba(255, 255, 255, 0.314) !important;
  outline: none !important;
  padding: 0 !important;
  margin: 0 !important;
  cursor: pointer !important;
}

.art-subtitle-range::-webkit-slider-thumb {
  -webkit-appearance: none !important;
  width: 20px !important;
  height: 20px !important;
  border-radius: 50% !important;
  background: white !important;
  cursor: pointer !important;
  transition: all 0.15s ease-in-out !important;
}

.art-subtitle-range::-webkit-slider-thumb:hover {
  background: #d4d4d4 !important;
  transform: scale(1.2) !important;
}
.art-subtitle-range::-moz-range-thumb {
  width: 20px !important;
  height: 20px !important;
  border: 0 !important;
  border-radius: 50% !important;
  background: white !important;
  cursor: pointer !important;
  transition: background 0.15s ease-in-out !important;
}
.art-subtitle-range::-moz-range-thumb:hover {
  background: #d4d4d4 !important;
}

/* .art-video-player .art-settings .art-setting-radio .art-radio-item.current button, .art-video-player .art-settings .art-setting-radio .art-radio-item button:active {
  background-color: var(--accent) !important;
}

.art-video-player .art-settings .art-setting-radio .art-radio-item button {
  border-radius: 7px !important;
  font-size: 14px !important;
} */

.art-video-player {
  color: white !important;
}

.art-video-player .art-backdrop-filter {
  background-color: rgb(63, 63, 63) !important;
}

.art-video-player .art-bottom {
  background: rgb(0,0,0) !important;
  background: linear-gradient(0deg, rgba(0,0,0,0.8029586834733894) 0%, rgba(0,0,0,0.500437675070028) 29%, rgba(255,255,255,0) 100%) !important;
}

.art-video-player .art-control-selector .art-selector-list .art-selector-item {
  padding: 0px 20px !important;
  color: #ffffff;
  margin: 5px;
  border-radius: 7px;
  text-shadow: none !important;
}

.art-video-player .art-bottom:hover .art-progress .art-control-progress .art-control-progress-inner {
  height: 50% !important;
}

.art-video-player .art-bottom .art-progress .art-control-progress .art-control-progress-inner,.art-video-player .art-bottom .art-progress .art-control-progress .art-control-progress-inner .art-progress-played,.art-video-player .art-bottom .art-progress .art-control-progress .art-control-progress-inner .art-progress-loaded {
  border-radius: 50px !important;
}

.art-video-player .art-bottom:hover .art-progress .art-control-progress .art-control-progress-inner .art-progress-indicator {
  border: 1px solid white !important;
  transform: scale(1.7) !important;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.art-video-player .art-bottom .art-progress .art-control-progress .art-control-progress-inner .art-progress-tip {
  top: -35px !important;
}

.art-video-player.art-control-show {
  transition: visibility 0s linear 300ms, opacity 300ms !important;
}

.art-icon-state {
  margin: 0 !important;
}

.art-video-player .art-mini-header {
  display: none !important;
}

.art-video-player .art-mini-header .art-mini-title {
  display: none !important;
}

.art-video-player .art-mini-close {
  display: none !important;
  opacity: 0 !important;
  visibility: hidden !important;
}

.art-video-player .art-notice .art-notice-inner {
  padding: 10px !important;
}

.art-video-player .art-notice .art-notice-inner {
  position: absolute !important;
  right: 0 !important;
}

.art-video-player .art-bottom .art-progress .art-control-progress .art-control-progress-inner .art-progress-indicator {
  visibility: visible !important;
  transform: scale(1.5) !important;
  border: 1px solid white !important;
}

@media (max-width:767px) {
  .art-video-player .art-bottom .art-progress .art-control-progress {
    height: 50px !important;
    padding-top: 23px !important;
    padding-bottom: 23px !important;
  }
  .art-video-player .art-bottom .art-controls .art-control-onlyText {
    display: none !important;
  }
  .art-video-player .art-bottom .art-progress .art-control-progress .art-control-progress-inner .art-progress-indicator {
    transform: scale(2) !important;
  }
  .art-video-player .art-bottom:hover .art-progress .art-control-progress .art-control-progress-inner .art-progress-indicator {
    transform: scale(2) !important;
  }
}