.search-category-title {
    color: var(--text);
}

.play-logo-search-item {
    font-size: 40px;
    color: white;
}

.search-wrapper {
    padding: 20px 0px;
}

.sugoi-search-bar span {
    border: none;
    width: 60px;
    background-color: var(--bgSecondary);
}

.sugoi-search-bar span .bi-search {
    color: var(--text);
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
}

.sugoi-search-bar input {
    background-color: var(--bgSecondary);
    border: none;
    height: 50px;
    color: var(--s-white) !important;
}

.sugoi-search-bar input {
    -webkit-transition: all .1s ease-in-out;
    -moz-transition: all .1s ease-in-out;
    -ms-transition: all .1s ease-in-out;
    -o-transition: all .1s ease-in-out;
    transition: all .1s ease-in-out
}

.sugoi-search-bar input::placeholder {
    color: var(--text);
}

.sugoi-search-bar:focus-within {
    box-shadow: var(--nShadow);
}

.sugoi-search-bar:focus-within > input::placeholder {
    color: var(--contrast);
}