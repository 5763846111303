.navigation-back button {
    background-color: var(--glass);
    border: none;
    font-size: 20px;
    height: 50px;
    width: 50px;
    border-radius: 50% !important;
}

.mobile-navigation {
    position: absolute;
    z-index: 100;
    padding: 0px;
    margin: 0px;
    max-width: fit-content !important;
}

.navigation-back {
    padding: 10px !important;
}